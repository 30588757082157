import * as donationServices from 'services/donations.service';
import axios from 'axios';

export const SET_BENEFICIARIES = 'DONATIONS/SET_BENEFICIARIES';
export const SET_SELECTED_BENEFICIARIES = 'DONATIONS/SET_SELECTED_BENEFICIARIES';
export const SET_PROGRAMS = 'DONATIONS/SET_PROGRAMS';
export const SET_SELECTED_PROGRAM = 'DONATIONS/SET_SELECTED_PROGRAM';
export const SET_DENOMINATIONS = 'DONATIONS/SET_DENOMINATIONS';
export const SET_PAYMENTS_HISTORY = 'DONATIONS/SET_PAYMENTS_HISTORY';

export const SET_ZAKYAT_CALCULATOR_FORM_VALUES = 'DONATIONS/SET_ZAKYAT_CALCULATOR_FORM_VALUES';
export const SET_ZAKYAT_CALCULATION_FORM_STATE = 'DONATIONS/SET_ZAKYAT_CALCULATION_FORM_STATE';
export const SET_ZAKYAT_CALCUATION_TRIGGERRER = 'DONATIONS/SET_ZAKYAT_CALCUATION_TRIGGERRER';
export const SET_ZAKYAT_CALCULATOR_RESPONSE = 'DONATIONS/SET_ZAKYAT_CALCULATOR_RESPONSE';

export const SET_SELECTION_PARAMS = 'DONATIONS/SET_SELECTION_PARAMS';

export const CREATE_BENEFICIARIES_AMOUNT = 'DONATIONS/CREATE_BENEFICIARIES_AMOUNT';
export const CREATE_DONATION = 'DONATIONS/CREATE_DONATION';
export const CREATE_PAYMENT = 'DONATIONS/CREATE_PAYMENT';
export const INIT_PAYMENT_PAYBOX = 'DONATIONS/INIT_PAYMENT_PAYBOX';

export const setBeneficiaries = (beneficiaries) => (dispatch) => {
  dispatch({
    type: SET_BENEFICIARIES,
    payload: beneficiaries,
  });
};

export const setSelectedBeneficiaries = (beneficiaries) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_BENEFICIARIES,
    payload: beneficiaries,
  });
};

export const setPrograms = (programs) => (dispatch) => {
  dispatch({
    type: SET_PROGRAMS,
    payload: programs,
  });
};

export const setSelectedProgram = (program) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PROGRAM,
    payload: program,
  });
};

export const setDefaultDenominations = (denominations) => (dispatch) => {
  dispatch({
    type: SET_DENOMINATIONS,
    payload: denominations,
  });
};

export const setPaymentsHistory = (paymentsWithPagination) => (dispatch) => {
  dispatch({
    type: SET_PAYMENTS_HISTORY,
    payload: paymentsWithPagination,
  });
};

export const setZakyatCalculatorFormValues = (formValues) => (dispatch) => {
  dispatch({
    type: SET_ZAKYAT_CALCULATOR_FORM_VALUES,
    payload: formValues,
  });
};

export const setZakyatCalculationFormState = (formState) => (dispatch) => {
  dispatch({
    type: SET_ZAKYAT_CALCULATION_FORM_STATE,
    payload: formState,
  });
};

export const setZakyatCalculationTriggerrer = (shouldCalculate) => (dispatch) => {
  dispatch({
    type: SET_ZAKYAT_CALCUATION_TRIGGERRER,
    payload: shouldCalculate,
  });
};

export const setZakyatCalculationResponse = (values) => (dispatch) => {
  dispatch({
    type: SET_ZAKYAT_CALCULATOR_RESPONSE,
    payload: values,
  });
};

export const setSelectionParams = (params) => (dispatch) => {
  dispatch({
    type: SET_SELECTION_PARAMS,
    payload: params,
  });
};

export const createBeneficiaryAmounts = (bensWithAmount) => (dispatch) => {
  const requests = bensWithAmount.map((benWithAmount) => {
    const { id } = benWithAmount.beneficiary;
    const { amount } = benWithAmount;
    return donationServices.createBeneficiaryAmount({ beneficiaryId: id, amount });
  });

  let benAmountIds = [];
  return axios
    .all(requests)
    .then(
      axios.spread((...responses) => {
        benAmountIds = responses.map((r) => r.data.id);
        dispatch({
          type: CREATE_BENEFICIARIES_AMOUNT,
          payload: benAmountIds,
        });
        return Promise.resolve(benAmountIds);
      }),
    )
    .catch((errors) => {
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const createDonation = (
  programId,
  benAmountIds,
  random,
  donationAmount,
  certificateAmount,
) => (dispatch) => {
  const payload = {
    program: programId,
    donation_amount: donationAmount,
  };

  if (random) {
    payload.certificate_amount = certificateAmount;
  } else {
    payload.beneficiaries = benAmountIds;
    payload.certificate_amount = certificateAmount;
  }

  return donationServices
    .createDonation(payload)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: CREATE_DONATION,
        payload: data,
      });
      return Promise.resolve(data);
    })
    .catch((error) => Promise.reject(error));
};

export const createPayment = (donationId) => (dispatch) => {
  return donationServices
    .createPayment({ donationId })
    .then((response) => {
      const { data } = response;
      dispatch({
        type: CREATE_PAYMENT,
        payload: data,
      });
      return Promise.resolve(data);
    })
    .catch((error) => Promise.reject(error));
};

export const initPaymentPaybox = (pgOrderId) => (dispatch) => {
  return donationServices
    .initPayboxPayment({ pgOrderId })
    .then((response) => {
      dispatch({
        type: INIT_PAYMENT_PAYBOX,
        payload: response,
      });
      return Promise.resolve(response);
    })
    .catch((error) => Promise.reject(error));
};

export const processPayment = (
  random,
  programId,
  bensWithAmount,
  donationAmount,
  certificateAmount,
) => (dispatch) => {
  if (random) {
    return dispatch(createDonation(programId, [], true, donationAmount, certificateAmount)).then(
      (donation) => {
        return dispatch(createPayment(donation.id)).then((data) => {
          const pgOrderId = data.pg_order_id;
          return dispatch(initPaymentPaybox(pgOrderId));
        });
      },
    );
  } else {
    return dispatch(createBeneficiaryAmounts(bensWithAmount)).then((benAmountIds) => {
      return dispatch(createDonation(programId, benAmountIds, false, donationAmount, certificateAmount)).then((donation) => {
        return dispatch(createPayment(donation.id)).then((data) => {
          const pgOrderId = data.pg_order_id;
          return dispatch(initPaymentPaybox(pgOrderId));
        });
      });
    });
  }
};
